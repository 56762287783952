import { getParents, getRgba, getSource } from './util';
import Modal from './Components/Modal';
import handlers from './handlers';
import style from './flow.module.css';

class Flow {
  id = `Flow${parseInt(Date.now() * Math.random(), 10)}`;
  eventWrapper = () => {};
  properties = {};
  root = document.createElement('div');
  el = document.createElement('div');
  history = [];
  main = null;
  modal = new Modal({ flow: this });
  step = null;
  stepIndex = -1;
  styleColors = document.createElement('style');
  target = null;

  constructor({ main } = {}) {
    this.main = main;
    this.root.id = this.id;
    this.root.className = style.flow;
    this.root.dataset.visible = false;
    this.el.className = style.focus;
    this.root.append(this.styleColors);
    this.root.append(this.el);
    this.main.root.append(this.root);
  }

  destroy = () => {
    this.main.activeFlows = this.main.activeFlows.filter(
      ({ id }) => id !== this.id
    );
    document.querySelector(`.${style.flow}#${this.id}`).remove();
  };

  updateColors = () => {
    let { background = '#66000000' } = this.flow;
    const { value: backgroundColor = background } = getSource(
      background,
      this.main.data
    );
    background = getRgba(backgroundColor);
    const colors = {
      background
    };
    this.styleColors.innerHTML = `
      .${style.flow}#${this.id} {
          ${Object.keys(colors)
            .map((key) => `--${key}: ${colors[key]};`)
            .join('\n')}
      }
    `;
  };

  scrollListener = (event) => {
    this.setTargetPosition(event);
    this.modal.setModalPosition();
  };

  setTargetPosition(event) {
    const {
      x: targetX,
      y: targetY,
      width: targetWidth,
      height: targetHeight
    } = this.target.getBoundingClientRect();
    this.el.style.transition = event ? 'none' : '';
    this.el.style.transform = `translate(${targetX - 6}px,${targetY - 6}px)`;
    this.el.style.width = `${parseInt(targetWidth + 12, 10)}px`;
    this.el.style.height = `${parseInt(targetHeight + 12, 10)}px`;
  }

  focus = (target) => {
    if (this.target) {
      getParents(this.target).forEach((parent) =>
        parent.removeEventListener('scroll', this.scrollListener, {
          pasive: true
        })
      );
    }
    this.target = target;
    if (!target) {
      this.el.style.transform = `translate(${window.outerWidth / 2}px,${
        window.outerHeight / 2
      }px)`;
      this.el.style.width = '0';
      this.el.style.height = '0';
      return;
    }
    const parents = getParents(target);
    parents.forEach((parent) =>
      parent.addEventListener('scroll', this.scrollListener, { pasive: true })
    );
    this.setTargetPosition();
    this.modal.setModalPosition();
  };

  handleAdd = handlers.add.bind(this);

  handleBack = () => {
    this.back();
  };

  handleBrowser = handlers.browser.bind(this);

  handleClick = (event, { onclick = [] }) => {
    this.handleFunctions(onclick);
  };

  handleDelete = handlers.delete.bind(this);

  handleFinish = () => {
    this.finish();
  };

  handleFunctions = handlers.functions.bind(this);

  handleGoto = handlers.goTo.bind(this);

  handleIf = handlers.if.bind(this);

  handleNext = () => {
    this.next();
  };

  handleRequest = handlers.request.bind(this);

  handleSet = handlers.set.bind(this);

  handleTrack = handlers.track.bind(this);

  finish = () => {
    const { blocking } = this.step;
    const { overflow } = document.body.style;
    this.modal.hide();
    document.body.style.overflow = blocking ? '' : overflow;
    this.root.dataset.visible = false;
    this.stepIndex = -1;
    this.step = null;
    this.flow = null;
    this.destroy();
  };

  goTo = (stepId, { back, newLayer } = {}) => {
    if (newLayer) {
      this.main.goTo(stepId, { newLayer });
      return;
    }
    const flow = this.main.flows.find(({ steps = [] }) =>
      steps.some(({ id }) => id === stepId)
    );
    if (!flow) {
      return;
    }
    const { buttons: flowButtons = {}, steps = [] } = flow;
    const stepIndex = steps.map(({ id }) => id).indexOf(stepId);
    const step = steps[stepIndex];
    this.step && !back && this.history.push(this.step);
    this.flow = flow;
    this.step = step;
    this.stepIndex = stepIndex;
    this.updateColors();
    let {
      button_negative: buttonNegative,
      button_positive: buttonPositive,
      target = {},
      blocking,
      buttons: stepButtons = []
    } = step;
    this.root.dataset.blocking = blocking;
    document.body.style.overflow = blocking ? 'hidden' : '';
    this.root.dataset.visible = true;
    const actions = [
      ...(buttonNegative ? [flowButtons.negative] : []),
      ...stepButtons,
      ...(buttonPositive ? [flowButtons.positive] : [])
    ];
    const { selector, shape = 'square', onclick, onchange } = target;
    target = document.querySelector(selector);
    const eventType = !!onclick ? 'click' : !!onchange ? 'change' : '';
    const parameters = onclick || onchange || {};
    this.eventWrapper = (event) => this.handleEvent(event, parameters, target);
    target?.addEventListener(eventType, this.eventWrapper, false);
    this.el.dataset.shape = shape;
    this.el.dataset.hasTarget = !!target;
    this.focus(target);

    this.modal.set({
      ...step,
      actions,
      target,
      container: this.root
    });
  };

  back = () => {
    const step = this.history.pop();
    this.goTo(step.id, { back: true });
  };

  next = () => {
    const stepIndex = this.stepIndex + 1;
    const { steps = [] } = this.flow;
    const step = steps[stepIndex];
    if (!step) {
      this.finish();
      return;
    }
    this.goTo(step.id);
  };

  replaceFirebase = handlers.replaceFirebase.bind(this);
  replaceRefs = handlers.replaceRefs.bind(this);
}

export default Flow;
