import Firebase from 'firebase/app';
import ls from 'local-storage';

export const handlers = {
  add: async function ({ what = {}, key = '', to = '' }) {
    key = await this.replaceRefs(key);
    if (to.indexOf('@firebase.firestore.') !== 0) {
      return;
    }
    try {
      let _to = to.replace('@firebase.firestore.', '');
      let { firebase = {} } = this.main || this;
      let { firestore: docRef } = firebase;
      let colRef;
      while (_to.includes('.')) {
        _to = _to.split('.');
        const col = _to.shift();
        colRef = docRef.collection(col);
        if (_to.length) {
          const doc = _to.shift();
          docRef = colRef.doc(doc);
        }
        _to = _to.join('.');
      }
      colRef = docRef.collection(_to);
      const whatKeys = Object.keys(what);
      if (colRef && whatKeys.length > 0) {
        const replacedValues = await Promise.all(
          whatKeys.map((key) => this.replaceRefs(what[key]))
        );
        const replacedWhat = replacedValues.reduce(
          (whatReplaced, value, index) => ({
            ...whatReplaced,
            [whatKeys[index]]: value
          }),
          {}
        );
        key ? colRef.doc(key).set(replacedWhat) : colRef.add(replacedWhat);
      }
    } catch (error) {}
  },

  browser: async function ({ url, target = '_self' }) {
    const a = document.createElement('a');
    url = await this.replaceRefs(url);
    target = await this.replaceRefs(target);
    a.href = url;
    a.target = target;
    a.click();
    a.remove();
  },

  delete: async function ({ what }) {
    what = await this.replaceRefs(what, { onlyParanthesis: true });
    if (what.indexOf('@firebase.firestore.') !== 0) {
      return;
    }
    try {
      let _what = what.replace('@firebase.firestore.', '');
      let { firebase = {} } = this.main || this;
      let { firestore } = firebase;
      let colRef;
      let docRef = firestore;
      while (_what.includes('.')) {
        _what = _what.split('.');
        const col = _what.shift();
        const doc = _what.shift();
        try {
          colRef = col ? docRef.collection(col) : undefined;
        } catch (error) {
          console.error('>>>', error);
          _what = col;
          break;
        }
        docRef = doc ? colRef.doc(doc) : undefined;
        _what = _what.join('.');
      }
      if (_what) {
        docRef.update({
          [_what]: Firebase.firestore.FieldValue.delete()
        });
      } else if (docRef) {
        docRef.delete();
      } else if (colRef) {
        colRef.delete();
      } else {
        console.log('>>>');
      }
    } catch (error) {
      console.error('>>>', error);
    }
  },

  functions: async function (functions = []) {
    for (const entry of functions) {
      const { function: f, if: _if = true, ...rest } = entry;
      const handleIf = await this.handleIf(_if);
      if (_if === true || handleIf) {
        await (
          this[`handle${f[0]?.toUpperCase() + f?.slice(1)}`] || (() => {})
        )(rest);
      } else {
        const { else: _else = [] } = _if;
        _else.length && (await this.handleFunctions(_else));
      }
    }
  },

  if: async function ({
    what,
    is,
    is_not,
    less_than,
    more_than,
    in: _in,
    includes,
    not_into,
    ends_with,
    starts_with,
    and,
    or
  } = {}) {
    let _what = await this.replaceRefs(what);
    is = typeof is !== 'undefined' ? await this.replaceRefs(is) : undefined;
    is_not =
      typeof is_not !== 'undefined'
        ? await this.replaceRefs(is_not)
        : undefined;
    less_than =
      typeof less_than !== 'undefined'
        ? await this.replaceRefs(less_than)
        : undefined;
    more_than =
      typeof more_than !== 'undefined'
        ? await this.replaceRefs(more_than)
        : undefined;
    _in = typeof _in !== 'undefined' ? await this.replaceRefs(_in) : undefined;
    includes =
      typeof includes !== 'undefined'
        ? await this.replaceRefs(includes)
        : undefined;
    not_into =
      typeof not_into !== 'undefined'
        ? await this.replaceRefs(not_into)
        : undefined;
    ends_with =
      typeof ends_with !== 'undefined'
        ? await this.replaceRefs(ends_with)
        : undefined;
    starts_with =
      typeof starts_with !== 'undefined'
        ? await this.replaceRefs(starts_with)
        : undefined;
    return (
      (((typeof is !== 'undefined' && _what === is) ||
        (typeof is_not !== 'undefined' && _what !== is_not) ||
        (typeof less_than !== 'undefined' && _what < less_than) ||
        (typeof more_than !== 'undefined' && _what > more_than) ||
        (typeof _in !== 'undefined' &&
          (_in.stringify?.() || `${_in}`).includes(_what)) ||
        (typeof includes !== 'undefined' &&
          _what.match(new RegExp(`${includes}`)) !== null) ||
        (typeof not_into !== 'undefined' &&
          _what.match(new RegExp(`${not_into}`)) === null) ||
        (typeof starts_with !== 'undefined' &&
          _what.match(new RegExp(`^${starts_with}`)) !== null) ||
        (typeof ends_with !== 'undefined' &&
          _what.match(new RegExp(`${ends_with}$`)) !== null)) &&
        (typeof and === 'undefined' || (await this.handleIf(and)))) ||
      (typeof or !== 'undefined' && (await this.handleIf(or)))
    );
  },

  goTo: async function ({ step, new_layer: newLayer }) {
    step = await this.replaceRefs(step);
    this.goTo(step, { newLayer });
  },

  replaceFirebase: async function ({ str, key }) {
    const { firebase = {} } = this.main || this;
    let { firestore: docRef } = firebase;
    if (str.indexOf('@firebase.firestore.') !== 0 || !docRef) {
      return str;
    }
    let _str = str.replace('@firebase.firestore.', '');
    try {
      while (_str.includes('.')) {
        _str = _str.split('.');
        const col = _str.shift();
        const doc = _str.shift();
        docRef = docRef.collection(col).doc(doc);
        _str = _str.join('.');
      }
      let entry = (await docRef.get())?.data();
      let { [_str]: replacedStr = entry } = entry;
      replacedStr = isNaN(replacedStr) ? replacedStr : parseFloat(replacedStr);
      replacedStr = replacedStr === 'null' ? null : replacedStr;
      replacedStr = replacedStr === 'true' ? true : replacedStr;
      replacedStr = replacedStr === 'false' ? false : replacedStr;
      return replacedStr;
    } catch (error) {
      return null;
    }
  },

  replaceRefs: async function (str, { onlyParanthesis = false } = {}) {
    if (typeof str !== 'string') {
      return str;
    }
    const flow = this.flow || {};
    const step = this.step || {};
    const codoozerFlow = ls('codoozer-flow') || {};
    const { _cookies: cookie = {} } = codoozerFlow;
    const { app, properties: property } = this.main || this;
    const variables = {
      app,
      cookie,
      property,
      step,
      flow,
      window
    };

    if (!onlyParanthesis && str.indexOf('@firebase') === 0) {
      str = `${str}`.replace(
        /(\(@)([^.]+)\.([^)]+)\)?/g,
        (str, parenthesis, type, key) => {
          return key
            .split('.')
            .reduce(
              (resp, key) =>
                typeof resp[key] === 'undefined'
                  ? null
                  : typeof resp[key] === 'function'
                  ? resp[key]()
                  : resp[key],
              variables[type]
            );
        }
      );
      return await this.replaceFirebase({ str });
    }

    let strWithReplacedRefs = `${str}`.replace(
      onlyParanthesis
        ? /(\(@)([^.]+)\.([^)]+)\)?/g
        : /(^@|\(@)([^.]+)\.([^)]+)\)?/g,
      (str, parenthesis, type, key) => {
        return key
          .split('.')
          .reduce(
            (resp, key) =>
              typeof resp[key] === 'undefined'
                ? null
                : typeof resp[key] === 'function'
                ? resp[key]()
                : resp[key],
            variables[type]
          );
      }
    );
    strWithReplacedRefs = isNaN(strWithReplacedRefs)
      ? strWithReplacedRefs
      : parseFloat(strWithReplacedRefs);
    strWithReplacedRefs =
      strWithReplacedRefs === 'null' ? null : strWithReplacedRefs;
    strWithReplacedRefs =
      strWithReplacedRefs === 'true' ? true : strWithReplacedRefs;
    strWithReplacedRefs =
      strWithReplacedRefs === 'false' ? false : strWithReplacedRefs;
    return Promise.resolve(strWithReplacedRefs);
  },

  request: async function ({
    headers = {},
    into,
    method = 'get',
    onerror = [],
    onsuccess = [],
    params = {},
    response: responseType = 'plain',
    url = ''
  }) {
    const response =
      responseType === 'json-api' ? 'application/json' : 'text/plain';
    const options = {
      headers: { ...headers, 'Content-Type': response },
      method
    };
    url = await this.replaceRefs(url);
    await Promise.all(
      Object.keys(params).map(async (key) => {
        const value = await this.replaceRefs(params[key]);
        params[key] = value;
        return { [key]: value };
      })
    );
    if (method === 'post') {
      options.body = JSON.stringify(params);
    } else if (Object.keys(params).length) {
      url = `${url}?${Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join('&')}`;
    }

    fetch(url, options)
      .then((res) =>
        response === 'application/json' ? res.json() : res.text()
      )
      .then((value) => {
        if (into) {
          this.handleSet({ what: into, value });
        }
        setTimeout(() => this.handleFunctions(onsuccess), 0);
      })
      .catch((error) => {
        setTimeout(() => this.handleFunctions(onerror), 0);
      });
  },

  set: async function ({ what, value }) {
    what = await this.replaceRefs(what, { onlyParanthesis: true });
    value = await this.replaceRefs(value);
    if (what.indexOf('@property.') === 0) {
      const property = what.replace('@property.', '');
      (this.main || this).properties[property] = value;
    } else if (what.indexOf('@cookie.') === 0) {
      const cookie = what.replace('@cookie.', '');
      const codoozerFlow = ls('codoozer-flow') || {};
      let { _cookies = {} } = codoozerFlow;
      _cookies = {
        ..._cookies,
        [cookie]: value
      };
      codoozerFlow._cookies = _cookies;
      ls('codoozer-flow', codoozerFlow);
    } else if (what.indexOf('@firebase.firestore.') === 0) {
      try {
        let _what = what.replace('@firebase.firestore.', '');
        let { firebase = {} } = this.main || this;
        let { firestore: docRef } = firebase;
        while (_what.includes('.')) {
          _what = _what.split('.');
          const col = _what.shift();
          const doc = _what.shift();
          docRef = docRef.collection(col).doc(doc);
          _what = _what.join('.');
        }
        if (docRef && _what) {
          docRef.set({ [_what]: value }, { merge: true });
        }
      } catch (error) {}
    }
  },

  track: async function ({ action, category, label }) {
    action = await this.replaceRefs(action);
    category = await this.replaceRefs(category);
    label = await this.replaceRefs(label);
    (this.main || this).analytics?.track(action, { category, label });
  }
};

export default handlers;
