import { getLanguage, isObject } from '../../util';
import style from './button.module.css';

class Button {
  el = null;
  flow = null;

  constructor({ label, onclick, flow }) {
    const language = getLanguage();
    this.flow = flow;
    const el = document.createElement('button');
    el.className = style.button;
    el.onclick = (event) => this.flow.handleClick(event, { onclick });
    let labelToShow = label;
    if (isObject(labelToShow)) {
      labelToShow =
        typeof labelToShow[language] !== 'undefined'
          ? labelToShow[language]
          : labelToShow.default;
    }
    (async () => {
      labelToShow = await this.flow.replaceRefs(labelToShow);
      el.innerText = labelToShow;
    })();
    this.el = el;
    return this;
  }
}

export default Button;
