import JSZip from 'jszip';
import mimeTypes from './mimeTypes.json';

export const backgroundTypes = {
  TRANSPARENT: 'transparent',
  SOLID: 'solid',
  GRADIENT: 'gradient',
  IMAGE: 'image'
};

export const halignTypes = {
  START: 'start',
  CENTER: 'center',
  END: 'end'
};

export const valignTypes = {
  TOP: 'top',
  MIDDLE: 'middle',
  BOTTOM: 'bottom'
};

const marginTypes = {
  BOTTOM: 'bottom',
  END: 'end',
  START: 'start',
  TOP: 'top'
};

const marginKeys = {
  [marginTypes.TOP]: 'Top',
  [marginTypes.BOTTOM]: 'Bottom',
  [marginTypes.START]: 'Left',
  [marginTypes.END]: 'Right'
};
const paddingKeys = marginKeys;
const borderKeys = marginKeys;

const allKeys = ['@app', '@color', '@menu'];

export const getFile = (key, data) => {
  if (!key || !data) {
    return {};
  }
  const { res = [] } = data;
  const file = res.find(({ name }) => name === `res/${key}`) || {};
  return file;
};

export const getSource = (key, data) => {
  let value;

  if (!key || !data) {
    return { value, keys: allKeys };
  }

  const keys = allKeys.filter((k) => k.includes(key));

  if (key.includes('@')) {
    if (key.includes('@app.')) {
      key = key.replace('@app.', '@app.android.');
    }
    if (key.includes('@color.')) {
      key = key.replace('@color.', '@app.colors.');
    }
    if (key.includes('@font.')) {
      key = key.replace('@font.', '@app.fonts.');
    }
    if (key.includes('@menu.')) {
      key = key.replace('@menu.', '@menus.');
    }
    const subKeys = key.replace('@', '').split('.');
    value =
      subKeys.reduce(
        (value, subKey, i) =>
          (subKey === 'index' && parseInt(subKeys[i - 1], 10) + 1) ||
          (value && value[subKey]) ||
          (Array.isArray(value) && value.find(({ id }) => id === subKey)) ||
          (value && value.items && value.items[subKey]),
        data
      ) || value;
  }

  return { value, keys };
};

export const getLanguage = () => {
  return (navigator.language || navigator.userLanguage).split('-')[0];
};

export const getMimeType = (ext) => {
  return mimeTypes[ext] || '';
};

const isValidHex = (hex) => /^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex);
const getChunksFromString = (st, chunkSize) =>
  st.match(new RegExp(`.{${chunkSize}}`, 'g'));
const convertHexUnitTo256 = (hexStr) =>
  parseInt(hexStr.repeat(2 / hexStr.length), 16);
const getAlphafloat = (a, alpha) => {
  if (typeof alpha !== 'undefined') {
    if (alpha > 1 && alpha <= 100) {
      return alpha / 100;
    }
    if (alpha >= 0 && alpha <= 1) {
      return alpha;
    }
  }
  if (typeof a !== 'undefined') {
    return a / 256;
  }
  return 1;
};
const hexToRGBA = (hex, alpha) => {
  if (!isValidHex(hex)) {
    return;
  }
  const chunkSize = Math.floor((hex.length - 1) / 3);
  let hexArr = getChunksFromString(hex.slice(1), chunkSize);
  hexArr = hexArr.length === 3 ? ['ff', ...hexArr] : hexArr;
  const [a, r, g, b] = hexArr.map(convertHexUnitTo256);
  return `rgba(${r}, ${g}, ${b}, ${getAlphafloat(a, alpha)})`;
};
export const getRgba = (color, alpha) => {
  if (!color) {
    return;
  }
  if (!color.includes('#')) {
    return color;
  }
  return hexToRGBA(color, alpha);
};

export const getBackground = (background) => {
  const { type, color, direction = '', from, image, to } = background;
  switch (type) {
    case backgroundTypes.TRANSPARENT:
      return { background: 'transparent', boxShadow: 'none' };
    case backgroundTypes.SOLID:
      return {
        backgroundColor: `${getRgba(color) || ''}`.trim(),
        boxShadow: ''
      };
    case backgroundTypes.GRADIENT:
      return {
        background: `linear-gradient(${direction.replace(
          /_/g,
          ' '
        )}, ${from}, ${to})`,
        boxShadow: 'none'
      };
    case backgroundTypes.IMAGE:
      return { backgroundImage: `url(${image})`, boxShadow: 'none' };
    default:
      return { background: '' };
  }
};

export const getBorder = (borderColor = '') => {
  if (typeof borderColor === 'string') {
    return { border: `1px solid ${borderColor}` };
  }

  let style = {};
  Object.keys(borderColor).forEach((key) => {
    const _key = borderKeys[key];
    let color = borderColor[key];
    return (style = {
      ...style,
      [`border${`${_key}`}`]: `1px solid ${color}`
    });
  });
  return style;
};

export const getHeight = (height) => {
  if (height === 'wrap') {
    return { height: 'fit-content', minHeight: 'unset', maxHeight: '' };
  }
  height =
    `${height}`.includes('%') || isNaN(parseInt(height, 10))
      ? height
      : `${`${height}`.replace('dp', '')}px`;
  return { height, minHeight: height, maxHeight: height };
};

export const getWidth = (width) => {
  if (width === 'wrap') {
    return { width: 'fit-content', minWidth: 'unset', maxWidth: '' };
  }
  if (width === 'fill') {
    return { width: '0', minWidth: 'unset', maxWidth: 'unset', flexGrow: 1 };
  }
  width = width > window.outerWidth ? window.outerWidth : width;
  width =
    `${width}`.includes('%') || isNaN(parseInt(width, 10))
      ? width
      : `${`${width}`.replace('dp', '')}px`;
  return { width, minWidth: width, maxWidth: width };
};

export const getMargin = (margin = '') => {
  if (typeof margin === 'number') {
    return { margin: `${margin}px` };
  }
  if (typeof margin === 'string') {
    return { margin: margin.replace('dp', 'px') };
  }

  let style = {};
  Object.keys(margin).forEach((key) => {
    const _key = marginKeys[key];
    const numberStr =
      typeof margin[key] === 'number'
        ? `${margin[key]}px`
        : margin[key].replace('dp', '');
    return (style = {
      ...style,
      [`margin${`${_key}`}`]: numberStr
    });
  });
  return style;
};

export const getPadding = (padding = '') => {
  if (typeof padding === 'number') {
    return { padding: `${padding}px` };
  }
  if (typeof padding === 'string') {
    return { padding: padding.replace('dp', 'px') };
  }

  let style = {};
  Object.keys(padding).forEach((key) => {
    const _key = paddingKeys[key];
    const numberStr =
      typeof padding[key] === 'number'
        ? `${padding[key]}px`
        : padding[key].replace('dp', '');
    return (style = {
      ...style,
      [`padding${`${_key}`}`]: numberStr
    });
  });
  return style;
};

export const getParents = (el = {}, { parents = [] } = {}) => {
  const { parentElement } = el;
  if (!parentElement) {
    parents.push(document);
    return;
  }
  parents.push(parentElement);
  getParents(parentElement, { parents });
  return parents;
};

export const isObject = (o) => o !== null && typeof o === 'object';

export const unzip = (zip) =>
  new Promise((resolve, reject) => {
    (async () => {
      try {
        const uncompressedZip = await JSZip.loadAsync(zip);
        const { files = {} } = uncompressedZip;
        const entries = (
          await Promise.all(
            Object.values(files).map(async (file) => {
              const { name } = file;
              return file.async('blob').then(async (blob) => {
                if (!blob.size) {
                  return;
                }
                const ext = name.split('.').pop();
                blob = blob.slice(0, blob.size, getMimeType(ext));
                const url = window.URL.createObjectURL(blob);
                return {
                  name,
                  blob,
                  url
                };
              });
            })
          )
        ).filter((entry) => entry);
        resolve(entries);
      } catch (e) {
        console.error('>>>', e);
      }
    })();
  });
