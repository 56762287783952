import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import parse from 'html-react-parser';
import YouTubeLib from 'react-youtube';
import {
  getLanguage,
  getBackground,
  getHeight,
  getWidth,
  isObject,
  unzip
} from '../../util';
import styles from './content.module.css';

const backgroundTypes = {
  TRANSPARENT: 'transparent',
  SOLID: 'solid',
  GRADIENT: 'gradient',
  IMAGE: 'image'
};
const componentTypes = {
  GROUP: 'group',
  IMAGE: 'image',
  INPUTTEXT: 'inputtext',
  LIST: 'list',
  PDFVIEWER: 'pdfviewer',
  TEXT: 'text',
  VIDEO: 'video',
  WEB: 'web',
  YOUTUBE: 'youtube'
};
const halignTypes = {
  START: 'start',
  CENTER: 'center',
  END: 'end'
};
const marginTypes = {
  BOTTOM: 'bottom',
  END: 'end',
  START: 'start',
  TOP: 'top'
};
const orientationTypes = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical'
};
const valignTypes = {
  TOP: 'top',
  MIDDLE: 'middle',
  BOTTOM: 'bottom'
};
const marginKeys = {
  [marginTypes.TOP]: 'Top',
  [marginTypes.BOTTOM]: 'Bottom',
  [marginTypes.START]: 'Left',
  [marginTypes.END]: 'Right'
};
const paddingKeys = marginKeys;
const borderKeys = marginKeys;

const useMutationObserver = () => {};

export const PreviewContext = createContext({});

const allKeys = ['@app', '@color', '@menu'];

export const getFile = (key, { context = {} } = {}) => {
  const { data = {} } = context;

  if (!key) {
    return {};
  }
  const { res = [] } = data;
  const file = res.find(({ name }) => name === `res/${key}`) || {};
  return file;
};

export const getSource = async (key = '', { context = {} } = {}) => {
  const { data = {}, flow } = context;
  const { properties: property = {} } = flow.main;
  let value;

  if (!key) {
    return { value, keys: allKeys };
  }

  const keys = allKeys.filter((k) => k.includes(key));

  if (key.includes('@')) {
    if (key.includes('@app.')) {
      key = key.replace('@app.', '@app.android.');
    }
    if (key.includes('@color.')) {
      key = key.replace('@color.', '@app.colors.');
    }
    if (key.includes('@font.')) {
      key = key.replace('@font.', '@app.fonts.');
    }
    if (key.includes('@menu.')) {
      key = key.replace('@menu.', '@menus.');
    }
    const subKeys = key.replace('@', '').split('.');
    value =
      subKeys.reduce(
        (value, subKey, i) =>
          (subKey === 'index' && parseInt(subKeys[i - 1], 10) + 1) ||
          (value && value[subKey]) ||
          (Array.isArray(value) && value.find(({ id }) => id === subKey)) ||
          (value && value.items && value.items[subKey]),
        { ...data, property }
      ) || value;
  }
  value = await flow.replaceRefs(value || key);
  return { value, keys };
};

const getBorder = (borderColor = '') => {
  if (typeof borderColor === 'string') {
    return { border: `1px solid ${borderColor}` };
  }

  let style = {};
  Object.keys(borderColor).forEach((key) => {
    const _key = borderKeys[key];
    let color = borderColor[key];
    return (style = {
      ...style,
      [`border${`${_key}`}`]: `1px solid ${color}`
    });
  });
  return style;
};

const getAlign = (align) => {
  switch (align) {
    case halignTypes.START:
      return 'flex-start';
    case halignTypes.CENTER:
      return 'center';
    case halignTypes.END:
      return 'flex-end';
    default:
      return 'flex-start';
  }
};

const getMargin = (margin = '') => {
  if (typeof margin === 'number') {
    return { margin: `${margin}px` };
  }
  if (typeof margin === 'string') {
    return { margin: margin.replace('dp', 'px') };
  }

  let style = {};
  Object.keys(margin).forEach((key) => {
    const _key = marginKeys[key];
    const numberStr =
      typeof margin[key] === 'number'
        ? `${margin[key]}px`
        : margin[key].replace('dp', '');
    return (style = {
      ...style,
      [`margin${`${_key}`}`]: numberStr
    });
  });
  return style;
};
const getPadding = (padding = '') => {
  if (typeof padding === 'number') {
    return { padding: `${padding}px` };
  }
  if (typeof padding === 'string') {
    return { padding: padding.replace('dp', 'px') };
  }

  let style = {};
  Object.keys(padding).forEach((key) => {
    const _key = paddingKeys[key];
    const numberStr =
      typeof padding[key] === 'number'
        ? `${padding[key]}px`
        : padding[key].replace('dp', '');
    return (style = {
      ...style,
      [`padding${`${_key}`}`]: numberStr
    });
  });
  return style;
};

const getVisibility = (visible) => {
  const visibility = visible ? 'visible' : 'hidden';
  const display = !visible && 'none';
  return { visibility, display };
};

const getStyle = (attributes) => {
  const {
    background,
    border,
    height,
    margin,
    onclick,
    padding,
    position,
    visible,
    width
  } = attributes;

  let style = {};
  style =
    typeof background !== 'undefined'
      ? { ...style, ...getBackground(background) }
      : style;
  style =
    typeof border !== 'undefined' ? { ...style, ...getBorder(border) } : style;
  style =
    typeof height !== 'undefined' ? { ...style, ...getHeight(height) } : style;
  style =
    typeof margin !== 'undefined' ? { ...style, ...getMargin(margin) } : style;
  style =
    typeof onclick !== 'undefined'
      ? {
          ...style,
          cursor:
            onclick[0] && onclick[0].function === 'browser'
              ? 'pointer'
              : 'default'
        }
      : style;
  style =
    typeof padding !== 'undefined'
      ? { ...style, ...getPadding(padding) }
      : style;
  style = typeof position !== 'undefined' ? { ...style, position } : style;
  style =
    typeof visible !== 'undefined'
      ? { ...style, ...getVisibility(visible) }
      : style;
  style =
    typeof width !== 'undefined' ? { ...style, ...getWidth(width) } : style;

  return style;
};

const Wrapper = ({
  children,
  className = '',
  onClick: handleClick = () => {},
  ...initAttributes
}) => {
  const context = useContext(PreviewContext);
  const [attributes, setAttributes] = useState(initAttributes);
  const {
    halign,
    height,
    id,
    onclick = [],
    orientation,
    position,
    type,
    valign,
    width
  } = attributes;
  const flexDirection =
    orientation && orientation === orientationTypes.HORIZONTAL
      ? 'row'
      : 'column';

  useEffect(() => {
    (async () => {
      let { background = {}, borderColor = '' } = attributes;
      if (typeof background === 'string') {
        background = { type: backgroundTypes.SOLID, color: background };
      }
      const { color, from, to, image } = background;
      const { url } = getFile(image, { context });
      if (color && color.includes('@')) {
        const { value: _color } = await getSource(color, { context });
        background = { ...background, color: _color };
      }
      if (from && from.includes('@')) {
        const { value: _from } = await getSource(from, { context });
        background = { ...background, from: _from };
      }
      if (to && to.includes('@')) {
        const { value: _to } = await getSource(to, { context });
        background = { ...background, to: _to };
      }
      if (borderColor.includes('@')) {
        const { value: _borderColor = '' } = await getSource(borderColor, {
          context
        });
        borderColor = _borderColor;
      }
      if (image) {
        background = { ...background, image: url };
      }
      const sourceAttributes = { background, borderColor };
      setAttributes((attributes) => ({ ...attributes, ...sourceAttributes }));
    })();
  }, []);

  return (
    <div
      className={styles.componentWrapper}
      data-component-id={id || ''}
      data-direction={flexDirection}
      data-halign={halign}
      data-height={height}
      data-position={position}
      data-type={type}
      data-valign={valign}
      data-width={width}
      data-clickable={!!onclick.length}
      style={getStyle(attributes)}
      onClick={(event) => {
        onclick.length && handleClick(event, { onclick });
      }}
    >
      {children}
    </div>
  );
};

const Group = (attributes) => {
  const {
    data_source: dataSource,
    onClick: handleClick,
    orientation,
    scroll
  } = attributes;
  let { content = [] } = attributes;
  content = Array.isArray(content) ? content.filter((child) => child) : [];
  const flexDirection =
    orientation && orientation === orientationTypes.HORIZONTAL
      ? 'row'
      : 'column';
  const contentWithDataSource = content.map((component, index) => {
    const { field } = component || {};
    return {
      ...component,
      source: `${dataSource}.${field}`,
      index
    };
  });

  return (
    <Wrapper {...attributes} onClick={handleClick}>
      {(content && content.length && (
        <div
          className={styles.visor}
          data-direction={flexDirection}
          data-scroll={scroll}
        >
          <Preview
            content={dataSource ? contentWithDataSource : content}
            onClick={handleClick}
          />
        </div>
      )) ||
        null}
    </Wrapper>
  );
};

const Image = (initAttributes) => {
  const language = getLanguage();
  const context = useContext(PreviewContext);
  const { flow } = context;
  const [attributes, setAttributes] = useState(initAttributes);
  const { file, height, id, scale, source, _source } = attributes;
  let fileToShow = _source || file;
  if (isObject(fileToShow)) {
    fileToShow =
      typeof fileToShow[language] !== 'undefined'
        ? fileToShow[language]
        : fileToShow.default;
  }

  useEffect(() => {
    (async () => {
      const { value: _source = '' } = await getSource(source, { context });
      const sourceAttributes = {};
      if (source && source.includes('@')) {
        sourceAttributes._source = _source;
      }
      setAttributes((attributes) => ({ ...attributes, ...sourceAttributes }));
    })();
  }, []);

  if (typeof fileToShow === 'undefined' || fileToShow === '') {
    fileToShow = '?';
  }

  const { url = fileToShow } = getFile(fileToShow, { context });
  const style = {};
  const dataScale = height !== 'wrap' && scale;

  if (dataScale) {
    style.backgroundImage = `url(${url})`;
  }
  return (
    <Wrapper {...attributes}>
      <div data-scale={dataScale} style={style}>
        <img
          src={url}
          alt={id}
          onLoad={() => flow?.modal?.setModalPosition()}
        />
      </div>
    </Wrapper>
  );
};

const InputText = (initAttributes) => {
  const language = getLanguage();
  const context = useContext(PreviewContext);
  const { flow } = context;
  const [attributes, setAttributes] = useState(initAttributes);
  let {
    color = '@color.primaryText',
    border_color: borderColor = '@color.primaryText',
    border_color_focus: borderColorFocus = '@color.primary',
    hint = '',
    hint_color: hintColor = '@color.secondaryText',
    id,
    onchange = [],
    source = '',
    _source = '',
    value = ''
  } = attributes;

  useEffect(() => {
    (async () => {
      const { value: _source = '' } = await getSource(source, { context });
      const { value: _color = '' } = await getSource(color, { context });
      const { value: _borderColor = '' } = await getSource(borderColor, {
        context
      });
      const {
        value: _borderColorFocus = ''
      } = await getSource(borderColorFocus, { context });
      const { value: _hintColor = '' } = await getSource(hintColor, {
        context
      });
      const sourceAttributes = {};
      if (source && source.includes('@')) {
        sourceAttributes._source = _source;
      }
      if (color && color.includes('@')) {
        sourceAttributes.color = _color;
      }
      if (borderColor && borderColor.includes('@')) {
        sourceAttributes.border_color = _borderColor;
      }
      if (borderColorFocus && borderColorFocus.includes('@')) {
        sourceAttributes.border_color_focus = _borderColorFocus;
      }
      if (hintColor && hintColor.includes('@')) {
        sourceAttributes.hint_color = _hintColor;
      }
      setAttributes((attributes) => ({ ...attributes, ...sourceAttributes }));
    })();
  }, []);

  let hintToShow = hint;
  if (isObject(hintToShow)) {
    hintToShow =
      typeof hintToShow[language] !== 'undefined'
        ? hintToShow[language]
        : hintToShow.default;
  }
  if (typeof hintToShow === 'undefined' || hintToShow === '') {
    hintToShow = '?';
  }

  const handleChange = (event) => {
    const { currentTarget } = event;
    const { value } = currentTarget;
    flow.handleSet({ what: source, value });
    flow?.handleFunctions(onchange);
  };

  return (
    <Wrapper {...attributes}>
      <style>{`
                #${id}Input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                    color: ${hintColor} !important;
                }
                #${id}Input::-moz-placeholder { /* Firefox 19+ */
                    color: ${hintColor} !important;
                }
                #${id}Input:-ms-input-placeholder { /* IE 10+ */
                    color: ${hintColor} !important;
                }
                #${id}Input:-moz-placeholder { /* Firefox 18- */
                    color: ${hintColor} !important;
                }
                #${id}Input {
                    color: ${color} !important;
                    border-color: ${borderColor} !important;
                }
                #${id}Input:focus {
                    border-color: ${borderColorFocus} !important;
                }
            `}</style>
      <input
        key={`${id}Input${source}`}
        id={`${id}Input`}
        defaultValue={_source !== 'undefined' ? _source : value}
        placeholder={hintToShow}
        onChange={handleChange}
      />
    </Wrapper>
  );
};

const List = (initAttributes) => {
  const context = useContext(PreviewContext);
  const [attributes, setAttributes] = useState(initAttributes);
  const {
    data_source: dataSource,
    items = [],
    onClick: handleClick
  } = attributes;
  let { content = [] } = attributes;
  content = Array.isArray(content) ? content : [];

  useEffect(() => {
    (async () => {
      const { value: list = {} } = await getSource(dataSource, { context });
      let { items = [] } = list;
      items = Array.isArray(list) ? list : items;
      const sourceAttributes = {};
      if (dataSource && dataSource.includes('@')) {
        sourceAttributes.items = items;
      }
      setAttributes((attributes) => ({ ...attributes, ...sourceAttributes }));
    })();
  }, []);

  return (
    <Wrapper {...attributes}>
      {items.map((item, i) => (
        <div
          key={`list-item-${item.id}-${i}`}
          className={styles.visor}
          data-direction='column'
        >
          <Preview
            content={content.map((component, index) =>
              component.type === componentTypes.GROUP
                ? {
                    ...component,
                    data_source: `${dataSource}.${i}`
                  }
                : {
                    ...component,
                    index,
                    source: `${dataSource}.${i}.${component.field}`
                  }
            )}
            onClick={handleClick}
          />
        </div>
      ))}
    </Wrapper>
  );
};
const PdfViewer = (attributes) => {
  const language = getLanguage();
  const context = useContext(PreviewContext);
  const { id, file } = attributes;

  let fileToShow = file;
  if (isObject(fileToShow)) {
    fileToShow =
      typeof fileToShow[language] !== 'undefined'
        ? fileToShow[language]
        : fileToShow.default;
  }
  if (typeof fileToShow === 'undefined' || fileToShow === '') {
    fileToShow = '';
  }

  const { url = fileToShow } = getFile(fileToShow, { context });

  return (
    <Wrapper {...attributes}>
      <iframe title={id} src={url} />
    </Wrapper>
  );
};

const Text = (initAttributes) => {
  const language = getLanguage();
  const context = useContext(PreviewContext);
  const [attributes, setAttributes] = useState(initAttributes);
  let {
    color = '@color.primaryText',
    _font = '',
    font,
    font_size: fontSize = '',
    source,
    text,
    text_align: textAlign = 'start',
    text_valign: textValign,
    textToShow
  } = attributes;
  fontSize = `${`${fontSize}`.replace('dp', '')}px`;
  textAlign = getAlign(textAlign);

  useEffect(() => {
    (async () => {
      let textToShow = text;
      if (isObject(textToShow)) {
        textToShow =
          typeof textToShow[language] !== 'undefined'
            ? textToShow[language]
            : textToShow.default;
      }
      if (typeof textToShow === 'undefined' || textToShow === '') {
        textToShow = '?';
      }
      const { value: _textToShow = '' } = await getSource(textToShow, {
        context
      });
      const { value: _source = '' } = await getSource(source, { context });
      const { value: _color = '' } = await getSource(color, { context });
      const { value: _font = '' } = await getSource(font, { context });
      const sourceAttributes = {};
      _textToShow && (sourceAttributes.textToShow = _textToShow);
      _source && (sourceAttributes.text = _source);
      _color && (sourceAttributes.color = _color);
      _font && (sourceAttributes._font = _font);
      setAttributes((attributes) => ({ ...attributes, ...sourceAttributes }));
    })();
  }, []);

  const { url = '' } = getFile(_font || font, { context });

  let fontUrl;
  let fontFamily;
  if (font) {
    fontFamily = font.replace('@font.', '');
    fontUrl = url;
  }

  return (
    <Wrapper {...attributes}>
      {(fontUrl && (
        <style>{`
            @font-face {
                font-family: ${fontFamily};
                src: url(${fontUrl});
            }
        `}</style>
      )) ||
        null}
      <div
        data-align={textAlign}
        data-valign={textValign}
        style={{
          color,
          fontFamily,
          fontSize
        }}
        ref={(el) => {
          fontFamily &&
            el?.style.setProperty('font-family', fontFamily, 'important');
        }}
      >
        {parse(`${textToShow}`)}
      </div>
    </Wrapper>
  );
};

const Video = (attributes) => {
  const language = getLanguage();
  const context = useContext(PreviewContext);
  const { flow } = context;
  const {
    autoplay,
    file,
    height,
    loop,
    onfinished = [],
    onready = [],
    scale
  } = attributes;
  const dataScale = height !== 'wrap' && scale;

  let fileToShow = file;
  if (isObject(fileToShow)) {
    fileToShow =
      typeof fileToShow[language] !== 'undefined'
        ? fileToShow[language]
        : fileToShow.default;
  }
  if (typeof fileToShow === 'undefined' || fileToShow === '') {
    fileToShow = '';
  }

  const { url = fileToShow } = getFile(fileToShow, { context });
  const videoProps = { src: url };
  autoplay && (videoProps.autoPlay = true);
  loop && (videoProps.loop = true);

  const handleEnded = () => {
    flow.handleFunctions(onfinished);
  };

  const handleLoadedData = (event) => {
    if (!dataScale) {
      return;
    }
    const { currentTarget } = event;
    const { parentElement, videoHeight, videoWidth } = currentTarget;
    const {
      height: parentHeight,
      width: parentWidth
    } = parentElement.getBoundingClientRect();
    if (
      Math.abs(parentWidth / videoWidth) > Math.abs(parentHeight / videoHeight)
    ) {
      currentTarget.style.width = `${parentWidth}px`;
      currentTarget.style.height = `${
        (videoHeight * ((parentWidth * 100) / videoWidth)) / 100
      }px`;
    } else {
      currentTarget.style.height = `${parentHeight}px`;
      currentTarget.style.width = `${
        (videoWidth * ((parentHeight * 100) / videoHeight)) / 100
      }px`;
    }
    // currentTarget.dataset.vertical = videoHeight > videoWidth;

    flow.handleFunctions(onready);
  };

  return (
    <Wrapper {...attributes}>
      <div data-scale={dataScale}>
        <video
          {...videoProps}
          onLoadedData={handleLoadedData}
          onEnded={handleEnded}
        />
      </div>
    </Wrapper>
  );
};

const Web = (attributes) => {
  const language = getLanguage();
  const context = useContext(PreviewContext);
  const { file, id, url } = attributes;
  const iframe = useRef();
  const props = {};
  const mutationProps = {};

  let urlToShow = url;
  if (isObject(urlToShow)) {
    urlToShow =
      typeof urlToShow[language] !== 'undefined'
        ? urlToShow[language]
        : urlToShow.default;
  }
  if (typeof urlToShow === 'undefined' || urlToShow === '') {
    urlToShow = '';
  }

  let fileToShow = file;
  if (isObject(fileToShow)) {
    fileToShow =
      typeof fileToShow[language] !== 'undefined'
        ? fileToShow[language]
        : fileToShow.default;
  }
  if (typeof fileToShow === 'undefined' || fileToShow === '') {
    fileToShow = '';
  }
  const resource = getFile(fileToShow, { context });

  if (/^https:\/\//.test(urlToShow)) {
    props.src = urlToShow;
  } else {
    mutationProps.resource = resource;
    mutationProps.init = urlToShow || undefined;
  }

  useMutationObserver(iframe, mutationProps);

  useEffect(() => {
    unzip(resource);
  }, [resource]);

  return (
    <Wrapper {...attributes}>
      <iframe title={id} {...props} ref={iframe} />
    </Wrapper>
  );
};

const Youtube = (attributes) => {
  const language = getLanguage();
  const { flow } = useContext(PreviewContext);
  const {
    autoplay,
    id,
    video_id: videoId,
    onerror = [],
    onfinished = []
  } = attributes;

  let videoIdToShow = videoId;
  if (isObject(videoIdToShow)) {
    videoIdToShow =
      typeof videoIdToShow[language] !== 'undefined'
        ? videoIdToShow[language]
        : videoIdToShow.default;
  }
  if (typeof videoIdToShow === 'undefined' || videoIdToShow === '') {
    videoIdToShow = '';
  }

  const handleEnded = () => {
    flow.handleFunctions(onfinished);
  };

  const handleError = () => {
    flow.handleFunctions(onerror);
  };

  const opts = {
    playerVars: {
      autoplay
    }
  };

  return (
    <Wrapper {...attributes}>
      <div>
        <YouTubeLib
          videoId={videoIdToShow} // defaults -> null
          id={`Youtube${id || Date.now()}`} // defaults -> null
          className={''} // defaults -> null
          containerClassName={''} // defaults -> ''
          opts={opts} // defaults -> {}
          onReady={() => {}} // defaults -> noop
          onPlay={() => {}} // defaults -> noop
          onPause={() => {}} // defaults -> noop
          onEnd={handleEnded} // defaults -> noop
          onError={handleError} // defaults -> noop
          onStateChange={() => {}} // defaults -> noop
          onPlaybackRateChange={() => {}} // defaults -> noop
          onPlaybackQualityChange={() => {}} // defaults -> noop
        />
      </div>
    </Wrapper>
  );
};

const Component = (attributes) => {
  const { type } = attributes;

  switch (type) {
    case componentTypes.GROUP:
      return <Group {...attributes} />;
    case componentTypes.IMAGE:
      return <Image {...attributes} />;
    case componentTypes.INPUTTEXT:
      return <InputText {...attributes} />;
    case componentTypes.LIST:
      return <List {...attributes} />;
    case componentTypes.PDFVIEWER:
      return <PdfViewer {...attributes} />;
    case componentTypes.TEXT:
      return <Text {...attributes} />;
    case componentTypes.VIDEO:
      return <Video {...attributes} />;
    case componentTypes.WEB:
      return <Web {...attributes} />;
    case componentTypes.YOUTUBE:
      return <Youtube {...attributes} />;
    default:
      return null;
  }
};

export const Preview = ({ content, onClick: handleClick }) => {
  if (!content) {
    return null;
  }
  content = Array.isArray(content) ? content : [];
  return (
    <React.Fragment>
      {content.map((attributes, i) => {
        const {
          halign,
          height,
          position,
          valign,
          visible = true,
          width
        } = attributes;
        const style = visible
          ? { ...getHeight(height), ...getWidth(width), position }
          : {};
        return (
          <div
            key={`component-${i}-${Date.now()}`}
            style={style}
            data-halign={halign}
            data-position={position}
            data-valign={valign}
          >
            <Component {...attributes} onClick={handleClick} />
          </div>
        );
      })}
    </React.Fragment>
  );
};

const MainContent = ({ value, onComponentClick: handleClick }) => {
  const views = [value];
  return (
    <div className={styles.mainContent}>
      {views.map((view) => (
        <Group
          key={`main-content-${view.id}`}
          {...view}
          onClick={handleClick}
        />
      ))}
    </div>
  );
};

export const Content = ({
  value = {},
  data = {},
  flow,
  onComponentClick: handleClick
}) => {
  return (
    <PreviewContext.Provider value={{ data, flow }}>
      <div className={styles.previewWrapper}>
        <div>
          <div className={styles.content}>
            <MainContent value={value} onComponentClick={handleClick} />
          </div>
        </div>
      </div>
    </PreviewContext.Provider>
  );
};

export default Content;
