import Analytics from 'analytics';
import firebase from 'firebase/app';
import 'firebase/firestore';
import googleAnalytics from '@analytics/google-analytics';
import { getLanguage, isObject, unzip } from './util';
import handlers from './handlers';
import Flow from './Flow';
import style from './flow.module.css';

class Flows {
  eventWrapper = () => {};
  app = {
    date: () => new Date(window.Date.now()).toISOString().split('T')[0],
    time: () => new Date(window.Date.now()).toTimeString().split(' ')[0],
    timestamp: () => window.Date.now()
  };
  properties = {};
  data = {};
  entries = [];
  flows = [];
  activeFlows = [];
  root = document.createElement('div');
  styleColors = document.createElement('style');

  constructor({ hash = '', properties = {} } = {}) {
    this.properties = properties;
    this.loadCdz({ hash });
    this.root.className = style.root;
    this.root.append(this.styleColors);
    document.body.append(this.root);
  }

  loadCdz = ({ hash }) => {
    this.hash = hash;
    fetch(`https://kustod.io/s/${hash}.cdzt`)
      .then((resp) => resp.blob())
      .then((blob) => {
        unzip(blob).then(async (entries) => {
          entries = await Promise.all(
            entries.map(async (entry) => {
              return entry.name.match(/^flows\//) === null
                ? entry
                : await (await window.fetch(entry.url)).json();
            })
          );
          const res = entries.filter(({ name }) => /^res\//.test(name));
          const { url: appUrl } =
            entries.find(({ name }) => name === 'app.json') || {};
          const { url: configUrl } =
            entries.find(({ name }) => name === 'config.json') || {};
          const app = appUrl ? await (await window.fetch(appUrl)).json() : {};
          const config = configUrl
            ? await (await window.fetch(configUrl)).json()
            : {};
          this.entries = entries;
          this.data = { app, res };
          const {
            analytics = {},
            colors = {},
            events = {},
            firebase,
            project_name: projectName
          } = app;
          const { trackingId } = analytics;
          const { onready = [] } = events;
          this.styleColors.innerHTML = `
            .${style.root} {
              ${Object.keys(colors)
                .map((key) => `--${key}: ${colors[key]};`)
                .join('\n')}
            }
          `;
          this.flows = entries.filter(({ id, active = true }) => id && active);

          trackingId &&
            this.initAnalytics({ appName: projectName, ...analytics });
          firebase && this.initFirebase(firebase);

          const { viewer = {} } = config;
          const { accounts: { edges: accounts = [] } = {} } = viewer;
          const { node: account = {} } = accounts[0] || {};
          const site = window.location.hostname;
          fetch(
            `https://us-central1-doyo-c4526.cloudfunctions.net/flowInit?account_id=${account.id}&site=${site}`
          )
            .then((resp) => resp.json())
            .then(({ isPremium }) => {
              this.isPremium = isPremium;
              if (onready.length) {
                this.handleFunctions(onready);
              }
            });
        });
      });
  };

  initAnalytics = ({ appName, trackingId }) => {
    const analytics = Analytics({
      app: appName,
      version: 100,
      plugins: [
        googleAnalytics({
          trackingId
        })
      ]
    });
    this.analytics = analytics;
  };

  initFirebase = ({
    api_key: apiKey,
    app_id: appId,
    project_id: projectId
  }) => {
    const firebaseConfig = {
      apiKey,
      projectId,
      appId
    };
    const { id } = this.data?.app || {};
    !firebase.apps.some(({ name_ }) => name_ === '[DEFAULT]') &&
      firebase.initializeApp(firebaseConfig);
    firebase.apps.some(({ name_ }) => name_ === id)
      ? firebase.app(id)
      : firebase.initializeApp(firebaseConfig, id);
    this.firebase = { firestore: firebase.firestore() };
  };

  replaceFirebase = handlers.replaceFirebase.bind(this);
  replaceRefs = handlers.replaceRefs.bind(this);

  handleAdd = handlers.add.bind(this);

  handleBrowser = handlers.browser.bind(this);

  handleDelete = handlers.delete.bind(this);

  handleFunctions = handlers.functions.bind(this);

  handleGoto = handlers.goTo.bind(this);

  handleIf = handlers.if.bind(this);

  handleRequest = handlers.request.bind(this);

  handleSet = handlers.set.bind(this);

  handleTrack = handlers.track.bind(this);

  toIndex = () => {
    const language = getLanguage();
    const ul = document.createElement('ul');
    ul.innerHTML = this.flows
      .map(({ title, steps = [] }) => {
        let titleToShow = title;
        if (isObject(titleToShow)) {
          titleToShow =
            typeof titleToShow[language] !== 'undefined'
              ? titleToShow[language]
              : titleToShow.default;
        }
        return `<li><span>${titleToShow}</span>${
          !!steps.length &&
          `<ul>${steps.map(({ id }) => `<li>${id}</li>`).join('')}`
        }</li>`;
      })
      .join('');
    return ul;
  };

  handleEvent = (event, parameters, target) => {
    const { type } = event;
    target.removeEventListener(type, this.eventWrapper, false);
    this.eventWrapper = () => {};
    parameters.forEach(({ function: _function }) => {
      (this[_function] || (() => {}))();
    });
  };

  goTo = (stepId, { newLayer = false } = {}) => {
    let flow = this.activeFlows[this.activeFlows.length - 1];
    if (newLayer || !flow) {
      flow = new Flow({ main: this });
      this.activeFlows.push(flow);
    }
    flow.goTo(stepId);
  };

  start = (flowId, { newLayer } = {}) => {
    let flow = this.flows.find(({ id }) => id === flowId) || {};
    const activeFlow = this.activeFlows.find(({ flow }) => flow.id === flowId);
    const { events = {}, steps = [] } = flow;
    const { onstart = [] } = events;
    const firstStep = steps[0];
    newLayer = typeof newLayer === 'undefined' ? !activeFlow : newLayer;
    if (firstStep) {
      if (!newLayer) {
        activeFlow.destroy();
        newLayer = true;
      }
      this.goTo(firstStep.id, { newLayer });
      this.handleFunctions(onstart);
    }
  };

  update = ({ hash, properties }) => {
    this.properties = {
      ...this.properties,
      ...properties
    };

    if (hash && hash !== this.hash) {
      this.loadCdz({ hash });
    } else {
      const { app = {} } = this.data;
      const { events = {} } = app;
      const { onready = [] } = events;
      if (onready.length) {
        this.handleFunctions(onready);
      }
    }
  };
}

export default Flows;
