import ReactDOM from 'react-dom';
import Content from '../Content';
import Button from '../Button';
import {
  backgroundTypes,
  getBackground,
  getFile,
  getHeight,
  getLanguage,
  getPadding,
  getSource,
  getWidth,
  halignTypes,
  isObject,
  valignTypes
} from '../../util';
import style from './modal.module.css';

class Modal {
  root = document.createElement('div');
  wrap = document.createElement('div');
  modal = document.createElement('div');
  close = document.createElement('button');
  modalContent = document.createElement('div');
  header = document.createElement('div');
  body = document.createElement('div');
  actions = document.createElement('div');
  footer = document.createElement('div');
  creditLink = document.createElement('a');
  credit = document.createElement('div');
  style = {};

  constructor({ flow }) {
    this.flow = flow;
    this.root.className = style.root;
    this.wrap.className = style.wrap;
    this.wrap.tabindex = '-1';
    this.modal.className = style.modal;
    this.modal.role = 'document';
    this.modal.dataset.visible = 'false';
    this.modalContent.className = style.modalContent;
    this.close.className = style.close;
    this.close.onclick = () => this.flow.finish();
    this.header.className = style.header;
    this.body.className = style.body;
    this.actions.className = style.actions;
    this.credit.className = style.credit;
    this.creditLink.href = 'https://codoozer.com';
    this.creditLink.target = '_blank';
    this.footer.className = style.footer;
    this.credit.append(this.creditLink);
    this.footer.append(this.actions);
    this.modalContent.append(this.header);
    this.modalContent.append(this.body);
    this.modalContent.append(this.footer);
    this.modal.append(this.modalContent);
    this.wrap.append(this.modal);
    this.root.append(this.wrap);
    flow.root.append(this.root);

    window.addEventListener('resize', this.handleResize, false);
  }

  handleResize = () => {
    this.updateStyles();
    this.setModalPosition();
  };

  destroy = () => {
    window.removeEventListener('resize', this.handleResize, false);
  };

  updateClosable = ({ closable }) => {
    if (closable) {
      this.header.append(this.close);
    } else {
      this.close.remove();
    }
  };

  updateTitle = async ({ title }) => {
    const language = getLanguage();
    this.header.innerHTML = '';
    if (title) {
      const titleEl = document.createElement('div');
      titleEl.className = style.title;
      let titleToShow = title;
      if (isObject(titleToShow)) {
        titleToShow =
          typeof titleToShow[language] !== 'undefined'
            ? titleToShow[language]
            : titleToShow.default;
      }
      titleToShow = await this.flow.replaceRefs(titleToShow);
      titleEl.innerText = titleToShow;
      this.header.append(titleEl);
    }
  };

  updateContent = async ({ text, content, data }) => {
    const language = getLanguage();
    this.body.innerHTML = '';
    if (text) {
      const textEl = document.createElement('div');
      let textToShow = text;
      if (isObject(textToShow)) {
        textToShow =
          typeof textToShow[language] !== 'undefined'
            ? textToShow[language]
            : textToShow.default;
      }
      textToShow = await this.flow.replaceRefs(textToShow);
      textEl.innerText = textToShow;
      this.body.append(textEl);
    }
    if (content?.length) {
      function replaceContent(content) {
        return content.map(({ content, ...childAttrs }) => {
          return content
            ? { ...childAttrs, content: replaceContent(content) }
            : childAttrs;
        });
      }
      content = replaceContent(content);
      const contentEl = document.createElement('div');
      ReactDOM.render(
        <Content
          value={{ content }}
          data={data}
          flow={this.flow}
          onComponentClick={this.flow.handleClick}
        />,
        contentEl
      );
      this.body.append(contentEl);
    }
  };

  updateFooter = ({ actions }) => {
    const flow = this.flow;
    this.actions.innerHTML = '';
    if (actions?.length) {
      actions.forEach(({ label, onclick } = {}) => {
        const { el: actionEl } = new Button({ label, onclick, flow });
        this.actions.append(actionEl);
      });
    }
  };

  updateBackground = (background) => {
    if (typeof background === 'string') {
      background = { type: backgroundTypes.SOLID, color: background };
    }
    const data = this.flow.main.data;
    const { color, from, to, image } = background;
    const { value: _color } = getSource(color, data);
    const { value: _from } = getSource(from, data);
    const { value: _to } = getSource(to, data);
    const { url } = getFile(image, data);

    if (color && color.includes('@')) {
      background = { ...background, color: _color };
    }
    if (from && from.includes('@')) {
      background = { ...background, from: _from };
    }
    if (to && to.includes('@')) {
      background = { ...background, to: _to };
    }
    if (image) {
      background = { ...background, image: url };
    }

    background = getBackground(background);

    Object.keys(background).forEach(
      (key) => (this.modalContent.style[key] = background[key])
    );
  };

  updateWidth = (width, { maxWidth }) => {
    width = getWidth(width);
    width = typeof maxWidth !== 'undefined' ? { ...width, maxWidth } : width;
    Object.keys(width).forEach((key) => (this.modal.style[key] = width[key]));
  };

  updateHeight = (height, { maxHeight }) => {
    height = getHeight(height);
    height =
      typeof maxHeight !== 'undefined' ? { ...height, maxHeight } : height;
    Object.keys(height).forEach((key) => (this.modal.style[key] = height[key]));
  };

  updatePadding = (padding) => {
    padding = getPadding(padding);
    Object.keys(padding).forEach(
      (key) => (this.body.style[key] = padding[key])
    );
  };

  updateStyles = () => {
    let style = this.style;
    style = {
      background: '',
      margin: '',
      padding: '',
      width: 'wrap',
      height: 'wrap',
      max_width: 520,
      max_height: 520,
      ...style
    };
    const {
      width,
      max_width: maxWidth,
      height,
      max_height: maxHeight,
      halign,
      valign,
      ...rest
    } = style;
    this.updateWidth(width, { maxWidth });
    this.updateHeight(height, { maxHeight });
    Object.keys(rest).forEach((key) => {
      const value = style[key];
      (this[`update${key[0].toUpperCase() + key.slice(1)}`] || (() => {}))(
        value
      );
    });
  };

  set = (parameters = {}) => {
    if (!this.flow.main.isPremium) {
      this.footer.append(this.credit);
    }
    const {
      actions = [],
      target,
      title,
      text,
      closable,
      content,
      style = {}
    } = parameters;
    const data = this.flow.main.data;
    this.style = style;
    this.modalContent.dataset.visible = false;
    this.target = target;
    this.wrap.dataset.hasTarget = !!target;

    this.updateTitle({ title });
    this.updateClosable({ closable });
    this.updateContent({ text, content, data });
    this.updateFooter({ actions });
    setTimeout(() => {
      this.updateStyles();
      this.setModalPosition();
      this.modal.dataset.visible !== 'true' && this.appear();
      this.modalContent.dataset.appear = 'true';
      setTimeout(() => {
        delete this.modalContent.dataset.appear;
        this.modalContent.dataset.visible = true;
      }, 350);
      this.modal.dataset.visible = true;
    }, 100);
  };

  getXPosition = () => {
    const { halign, offset = {} } = this.style;
    const { x: offsetX = 0 } = offset;
    delete this.modal.dataset.arrowX;
    const { offsetWidth: modalWidth } = this.modal;
    switch (halign) {
      case halignTypes.START:
        return 16 + offsetX;
      case halignTypes.CENTER:
        return window.innerWidth / 2 - modalWidth / 2 + offsetX;
      case halignTypes.END:
        return window.innerWidth - modalWidth - 16 + offsetX;
      default:
        if (!this.target) {
          return window.innerWidth / 2 - modalWidth / 2 + offsetX;
        }
    }
    let {
      x: targetX,
      width: targetWidth
    } = this.target.getBoundingClientRect();

    const space = {
      left: targetX,
      center: targetWidth,
      right: window.innerWidth - (targetX + targetWidth)
    };
    let x;
    const maxSpace = Object.keys(space).reduce(
      (max, key) => (space[key] > space[max] ? key : max),
      'center'
    );
    if (space[maxSpace] < modalWidth) {
      x = targetX + targetWidth / 2 - modalWidth / 2 + offsetX;
    } else if (maxSpace === 'left') {
      x = targetX - modalWidth - 16 + offsetX;
    } else if (maxSpace === 'center') {
      x = targetX + targetWidth / 2 - modalWidth / 2 + offsetX;
    } else {
      x = targetX + targetWidth + 16 + offsetX;
    }

    if (x < 0) {
      x = 32 + offsetX;
      if (targetX + targetWidth < 0) {
        this.modal.dataset.arrowY = 'left';
      }
    } else if (x > window.innerWidth) {
      x = window.innerWidth - modalWidth - 32 + offsetX;
      if (targetX > window.innerWidth) {
        this.modal.dataset.arrowY = 'right';
      }
    }
    return x;
  };

  getYPosition = () => {
    const { valign, offset = {} } = this.style;
    const { y: offsetY = 0 } = offset;
    delete this.modal.dataset.arrowY;
    const { offsetHeight: modalHeight } = this.modal;
    switch (valign) {
      case valignTypes.TOP:
        return 16 + offsetY;
      case valignTypes.MIDDLE:
        return window.innerHeight / 2 - modalHeight / 2 + offsetY;
      case valignTypes.BOTTOM:
        return window.innerHeight - modalHeight - 16 + offsetY;
      default:
        if (!this.target) {
          return window.innerHeight / 2 - modalHeight / 2 + offsetY;
        }
    }
    let {
      y: targetY,
      height: targetHeight
    } = this.target.getBoundingClientRect();
    const space = {
      top: targetY,
      middle: targetHeight,
      bottom: window.innerHeight - (targetY + targetHeight)
    };
    let y;
    const maxSpace = Object.keys(space).reduce(
      (max, key) => (space[key] > space[max] ? key : max),
      'bottom'
    );
    if (space[maxSpace] < modalHeight) {
      y = targetY + targetHeight / 2 - modalHeight / 2 + offsetY;
    } else if (maxSpace === 'top') {
      y = targetY - modalHeight - 16 + offsetY;
    } else if (maxSpace === 'middle') {
      y = targetY + targetHeight / 2 - modalHeight / 2 + offsetY;
    } else {
      y = targetY + targetHeight + 16 + offsetY;
    }

    if (y < 0) {
      y = 32 + offsetY;
      if (targetY + targetHeight < 0) {
        this.modal.dataset.arrowY = 'top';
      }
    } else if (y > window.innerHeight) {
      y = window.innerHeight - modalHeight - 32 + offsetY;
      if (targetY > window.innerHeight) {
        this.modal.dataset.arrowY = 'bottom';
      }
    }
    return y;
  };

  setModalPosition = () => {
    const x = this.getXPosition();
    const y = this.getYPosition();

    this.modal.style.transform = `translate(${x}px, ${y}px)`;
  };

  appear = () => {
    this.wrap.dataset.appear = 'true';
    setTimeout(() => {
      delete this.wrap.dataset.appear;
      this.modal.dataset.visible = 'true';
    }, 300);
  };

  show = () => {
    this.modal.dataset.visible = 'true';
  };

  hide = () => {
    this.modal.dataset.visible = 'false';
  };
}

export default Modal;
